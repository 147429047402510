import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { UserDTO } from 'src/app/model/user-model';
import { UserConfigService } from 'src/app/services/user-config.service';

@Component({
  selector: 'rqa-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    CardModule,
    TranslateModule,
    DropdownModule,
    ButtonModule
  ]
})
export class AccountComponent implements OnInit, OnChanges {
  @Input() userConfig: UserDTO;
  private messageService = inject(MessageService);
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private userConfigService = inject(UserConfigService);
  accountForm: FormGroup;

  languages: { name: string; code: string }[] = [
    {
      name: 'Polski',
      code: 'PL'
    },
    {
      name: 'English',
      code: 'EN'
    }
  ];

  timeZones: { name: string; code: string }[] = [
    {
      name: 'LOCAL',
      code: 'LOCAL'
    },
    {
      name: 'UTC',
      code: 'UTC'
    }
  ];

  ngOnInit(): void {
    this.createAccountForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userConfig']) {
      this.patchAccountForm();
    }
  }

  save(): void {
    if (this.accountForm.valid) {
      const formValue = this.accountForm.getRawValue();
      const payload = {
        ...this.userConfig,
        locale: formValue.locale.code,
        time: formValue.time.code
      };
      this.userConfigService.updateUserConfig(payload).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('notifications.updateAccountSuccess'),
            life: 3000
          });
          window.location.reload();
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            detail: err.error.message,
            life: 3000
          });
        }
      });
    }
  }

  private createAccountForm(): void {
    this.accountForm = this.fb.group({
      locale: ['', Validators.required],
      time: ['', Validators.required]
    });
  }

  private patchAccountForm(): void {
    if (this.accountForm) {
      this.accountForm.patchValue({
        locale: this.languages.find((el) => el.code === this.userConfig.locale),
        time: this.timeZones.find((el) => el.code === this.userConfig.time)
      });
    }
  }
}
