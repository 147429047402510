import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rqa-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule]
})
export class ValidatorComponent implements OnInit, OnDestroy {
  @Input() control?: AbstractControl | null;
  @Input() formGroup?: UntypedFormGroup;
  translateService = inject(TranslateService);
  componentDestroyed$: Subject<boolean> = new Subject();
  errorMessage: string;

  ngOnInit(): void {
    if (!this.control && !this.formGroup) {
      console.error('Not transferred reference to control or formgroup');
      return;
    }
    this.errorMessage = this.setMessage();
    const valueChanges$ = this.control
      ? this.control.valueChanges
      : this.formGroup
        ? this.formGroup.valueChanges
        : null;

    if (valueChanges$) {
      valueChanges$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
        this.errorMessage = this.setMessage();
      });
    }
  }

  private setMessage(): string {
    const target = this.control || this.formGroup;

    if (!target) {
      return '';
    }

    if (target.status === 'DISABLED' || target.status === 'VALID') {
      return '';
    }

    return this.prepareErrorMessage(target.errors as ValidationErrors);
  }

  private prepareErrorMessage(errors: ValidationErrors): string {
    if (errors) {
      const firstKeyOfError = Object.keys(errors)[0];
      return this.translateService.instant(`form.errors.${firstKeyOfError}`, {
        args: errors[firstKeyOfError]
      }) as string;
    }
    return '';
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
