import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CopiedMapData } from '../model/map.model';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  constructor(private http: HttpClient) {}
  private layerSelectBehaviorSubject = new BehaviorSubject<string | undefined>(undefined);
  private $formLayerSelect = this.layerSelectBehaviorSubject.asObservable();

  private selectedRow = new BehaviorSubject<CopiedMapData | undefined>(undefined);
  private $selectedRow = this.selectedRow.asObservable();

  setLayerSelect(data: string | undefined) {
    this.layerSelectBehaviorSubject.next(data);
  }

  getLayerSelect(): Observable<string | undefined> {
    return this.$formLayerSelect;
  }

  selectRowToCopy(data: CopiedMapData | undefined) {
    this.selectedRow.next(data);
  }

  getSelectedRowToCopy(): Observable<CopiedMapData | undefined> {
    return this.$selectedRow;
  }
}
