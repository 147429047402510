import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { IconService } from './icon.service';

@Component({
  selector: 'rqa-icon',
  template: '<span [innerHTML]="icon | async"></span>',
  standalone: true,
  imports: [CommonModule]
})
export class IconComponent implements OnInit {
  @Input() iconName: string;
  @Input() height: string;
  @Input() width: string;
  icon: Observable<SafeHtml | null>;

  constructor(private iconService: IconService) {}

  ngOnInit() {
    this.icon = this.iconService.loadIcon(this.iconName, this.width, this.height);
  }
}
