<section class="flex gap-3 px-3 lg:px-0 flex-column lg:flex-row">
  <div class="lg:w-4">
    <h3>{{ 'userConfig.password' | translate }}</h3>
  </div>
  <p-card class="lg:w-8" [formGroup]="passwordForm">
    <div class="flex flex-column gap-2 mb-4">
      <label for="password">{{ 'userConfig.newPassword' | translate }}</label>
      <p-password
        id="password"
        formControlName="password"
        [toggleMask]="true"
        class="w-full"
        [mediumRegex]="PASSWORD_PATTERN_MEDIUM"
        [strongRegex]="PASSWORD_PATTERN_STRONG"
        maxLength="15"
      ></p-password>
      <div
        *ngIf="passwordForm.controls['password'].hasError('pattern') && passwordForm.get('password')?.touched"
        class="error"
      >
        <small id="username-help" class="p-error">{{ 'userConfig.passwordErrorMessage' | translate }}</small>
      </div>
    </div>
    <div class="flex flex-column gap-2 mb-4">
      <label for="confirm">{{ 'userConfig.confirmPassword' | translate }}</label>
      <p-password
        id="confirm"
        formControlName="confirm"
        [toggleMask]="true"
        [feedback]="false"
        class="w-full"
        maxLength="15"
        [ngClass]="{ 'ng-invalid ng-dirty': passwordForm.hasError('notSame') && passwordForm.get('confirm')?.touched }"
      ></p-password>
      <div *ngIf="passwordForm.hasError('notSame') && passwordForm.get('confirm')?.touched" class="error">
        <small id="username-help" class="p-error">{{ 'userConfig.passwordNotSame' | translate }}</small>
      </div>
    </div>
    <div class="flex justify-content-end">
      <p-button
        [label]="'buttons.save' | translate"
        icon="pi pi-check"
        (click)="save()"
        [disabled]="passwordForm.invalid"
      ></p-button>
    </div>
  </p-card>
</section>
