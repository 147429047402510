import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { StepperModule } from 'primeng/stepper';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { AddPolyComponent } from './add-poly/add-poly.component';
import { AddCircleComponent } from './add-circle/add-circle.component';
import { CoordinatesFormat } from 'src/app/model/map.model';
import { AddTimesComponent } from './add-times/add-times.component';
import { AdhocService } from 'src/app/services/adhoc.service';
import { Message, MessageService } from 'primeng/api';
import { AdHocDTO } from 'src/app/model/adhoc.model';
import { combineLatest, concat, timer } from 'rxjs';
import { MessagesModule } from 'primeng/messages';
import { AirspaceService } from 'src/app/services/airspace.service';

@Component({
  selector: 'rqa-adhoc',
  templateUrl: './adhoc.component.html',
  styleUrls: ['./adhoc.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    BadgeModule,
    DividerModule,
    ButtonModule,
    RadioButtonModule,
    TabViewModule,
    TranslateModule,
    IconComponent,
    StepperModule,
    MessagesModule,
    AddPolyComponent,
    AddCircleComponent,
    AddTimesComponent
  ]
})
export class AdhocComponent implements OnInit {
  @ViewChild(AddPolyComponent) polyComponent: AddPolyComponent;
  @ViewChild(AddCircleComponent) circleComponent: AddCircleComponent;
  coordinatesFormat = CoordinatesFormat;
  selectedCoordinatesFormat: CoordinatesFormat = CoordinatesFormat.DMS;
  adhocName: string;
  adhocPayload: AdHocDTO;
  canSent: boolean = false;
  resetForm: boolean = false;
  validationMessage: Message[] | undefined;
  isCircleView: boolean = true;
  activeStep: number = 0;
  private adhocService = inject(AdhocService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private airspaceService = inject(AirspaceService);

  ngOnInit(): void {
    this.getNewAdhocName();
    this.getStepsData();
  }

  onTabChange(event: TabViewChangeEvent): void {
    this.isCircleView = event.index === 0;
    this.adhocService.setCircleToDraw(null);
    this.adhocService.setPolyToDraw(null);
  }

  draw(): void {
    if (this.isCircleView) {
      this.circleComponent.draw();
    } else {
      this.polyComponent.draw();
    }
  }

  private getNewAdhocName() {
    this.adhocService.getNewAdHocName().subscribe({
      next: (response) => {
        this.adhocName = response.value;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          detail: error.error.message,
          life: 3000
        });
      }
    });
  }

  private getStepsData(): void {
    const formObservable = this.adhocService.getAdhocForm();
    const geoJsonObservable = this.adhocService.getGeoJson();
    combineLatest({
      form: formObservable,
      geoJson: geoJsonObservable
    }).subscribe({
      next: (results) => {
        if (!results.geoJson) {
          this.validationMessage = [
            { severity: 'error', detail: this.translateService.instant('adhoc.validationMessage') }
          ];
        } else {
          this.validationMessage = [];
        }
        this.canSent = results.form && results.geoJson;
        this.adhocPayload = {
          ...this.adhocPayload,
          ...results.form,
          designator: this.adhocName,
          geoJSON: results.geoJson
        };
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          detail: 'Failed to load data: ' + error,
          life: 3000
        });
      }
    });
  }

  createAdhoc(): void {
    this.adhocService.createAddHoc(this.adhocPayload).subscribe({
      next: (response) => {
        this.adhocService.setCircleToDraw(null);
        this.adhocService.setPolyToDraw(null);
        this.resetForm = true;
        this.messageService.add({
          severity: 'success',
          detail: response.value,
          life: 3000
        });
        this.activeStep = 0;
        concat(timer(5000), this.airspaceService.getAirspace()).subscribe();
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          detail: error.error.message,
          life: 3000
        });
      }
    });
  }
}
