@if (orders) {
  <p-treeTable
    #dt
    [styleClass]="'pansa-table'"
    [value]="orders"
    [columns]="cols"
    selectionMode="checkbox"
    [(selection)]="selectedNodes"
    [tableStyle]="{ 'min-width': '50rem' }"
    [scrollable]="true"
    scrollHeight="600px"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center lg:flex-row flex-column">
        <p-selectButton
          [options]="orderOptions"
          optionLabel="label"
          optionValue="value"
          [multiple]="false"
          [(ngModel)]="orderFilterStatus"
          (onChange)="filterByStatus($event)"
        ></p-selectButton>
        <div>
          <p-calendar
            [(ngModel)]="dateRange"
            dateFormat="yy-mm-dd 06:00"
            [iconDisplay]="'input'"
            [showIcon]="true"
            selectionMode="range"
            [readonlyInput]="true"
            [hideOnDateTimeSelect]="false"
            (onSelect)="filterByDate($event)"
            #calendar
          >
            <ng-template pTemplate="footer">
              <div class="flex justify-content-between">
                <p-button
                  [label]="'primeng.clear' | translate"
                  [text]="true"
                  icon="pi pi-times"
                  (click)="clearFilters()"
                ></p-button>
                <!-- <p-button
                  [label]="'buttons.filter' | translate"
                  icon="pi pi-check"
                  (click)="filterByDate()"
                  [disabled]="!dateRange"
                ></p-button> -->
              </div>
            </ng-template>
          </p-calendar>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="w-1 text-right">
          <p-treeTableHeaderCheckbox></p-treeTableHeaderCheckbox>
        </th>
        <th
          *ngFor="let col of columns"
          [ttSortableColumn]="col.field"
          class="text-xs pl-1 pr-2"
          [ngClass]="{
            'w-2': col.field === 'designator',
            'w-7rem': col.field === 'lowerAltitude' || col.field === 'upperAltitude'
          }"
        >
          {{ col.header }}
          <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
        <td
          *ngFor="let col of columns; let i = index"
          [ngClass]="{
            'w-3': col.field === 'designator',
            'w-7rem': col.field === 'lowerAltitude' || col.field === 'upperAltitude'
          }"
        >
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
          <p-treeTableCheckbox [value]="rowNode" *ngIf="i === 0"></p-treeTableCheckbox>
          @if (col.type === 'date') {
            {{ rowData[col.field] | date: 'yyyy-MM-dd HH:mm' }}
          } @else {
            {{ rowData[col.field] }}
          }
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td colspan="8">
          <p-button
            [label]="'buttons.cancel' | translate"
            [disabled]="!selectedNodes.length"
            (click)="onRemoveSelected()"
            [text]="true"
            [plain]="true"
          >
            <rqa-icon iconName="trash" class="p-button-icon-left"></rqa-icon>
          </p-button>
          <p-button
            [label]="'buttons.edit' | translate"
            [disabled]="!selectedNodes.length"
            (click)="onEditSelected()"
            [text]="true"
            [plain]="true"
          >
            <rqa-icon iconName="duplicate" class="p-button-icon-left"></rqa-icon>
          </p-button>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
}
