import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AirspaceElement, EPDAirspaceDTO } from '../model/map.model';

@Injectable({
  providedIn: 'root'
})
export class AirspaceService {
  http = inject(HttpClient);

  designators: string[] = [];
  private airspace = new BehaviorSubject<AirspaceElement[]>([]);
  private $airspace = this.airspace.asObservable();
  readonly API_URL: string = '/api/airspace';

  getAirspaceLayers(): Observable<AirspaceElement[]> {
    return this.http.get<AirspaceElement[]>(this.API_URL);
  }

  getAirspaceValues() {
    return this.$airspace;
  }

  getDesignators(): Observable<string[]> {
    return this.http.get<string[]>(`${this.API_URL}/designators`);
  }

  getAirspace(): Observable<AirspaceElement[]> {
    return this.http.get<AirspaceElement[]>(this.API_URL).pipe(tap((airspaces) => this.airspace.next(airspaces)));
  }

  findAirspace(id: number) {
    return this.airspace.value.filter((value) => value.id === id)[0];
  }

  findAirspaceByDesignator(designator: string) {
    const values = this.airspace.value.filter((value) => value.designator.toUpperCase() === designator);
    return values.length ? values[0] : null;
  }

  getEPDAirspace() {
    return this.http.get<EPDAirspaceDTO[]>(`${this.API_URL}/additions`);
  }
}
