import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelpDTO } from '../model/help-model';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  PATH = 'api/help';

  constructor(private http: HttpClient) {}

  getHelp(): Observable<HelpDTO> {
    return this.http.get<HelpDTO>(`${this.PATH}`);
  }
}
