import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { NoPolishCharsDirective } from 'src/app/shared/directives/no-polish-chars.directive';
import { TemplatesService } from 'src/app/services/templates.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'rqa-save-template-dialog',
  templateUrl: './save-template-dialog.component.html',
  styleUrls: ['./save-template-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    TranslateModule,
    NoPolishCharsDirective
  ]
})
export class SaveTemplateDialogComponent {
  private ref = inject(DynamicDialogRef);
  private templatesService = inject(TemplatesService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  templateName: string;
  validationError: string;

  save(): void {
    this.postTemplate(this.templateName);
  }

  close(): void {
    this.ref.close();
  }

  private postTemplate(templateName: string): void {
    this.templatesService.postTemplate(templateName).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.savedTemplate'),
          life: 3000
        });
        this.ref.close();
      },
      error: (err) => {
        this.validationError = err.error.message;
      }
    });
  }
}
