import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { OrdersService } from 'src/app/services/orders.service';
import { CancelledRqa } from 'src/app/model/reservation.model';
import { Table, TableModule } from 'primeng/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Calendar, CalendarModule } from 'primeng/calendar';
import * as moment from 'moment';
import { Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectButtonChangeEvent, SelectButtonModule } from 'primeng/selectbutton';
import { OrderStatus, OrderStatusOptions } from 'src/app/model/orders';
import { Route } from 'src/app/model/route.enum';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'rqa-canceled-orders',
  templateUrl: './canceled-orders.component.html',
  styleUrls: ['./canceled-orders.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    TranslateModule,
    CalendarModule,
    SelectButtonModule,
    RouterModule
  ],
  providers: [RouterModule]
})
export class CanceledOrdersComponent implements OnInit {
  @ViewChild('dt') pTable!: Table;
  @ViewChild('calendar') pCalendar!: Calendar;
  private ordersService = inject(OrdersService);
  private translateService = inject(TranslateService);
  private router = inject(Router);
  private dateService = inject(DateService);
  orderFilterStatus: OrderStatus = OrderStatus.CANCELLED;
  orders: CancelledRqa[];
  dateRange: Date[];
  orderOptions: OrderStatusOptions[] = [
    {
      label: this.translateService.instant('buttons.sent'),
      value: OrderStatus.SENT
    },
    {
      label: this.translateService.instant('buttons.canceled'),
      value: OrderStatus.CANCELLED
    }
  ];

  ngOnInit(): void {
    this.getCancelledOrders();

    this.translateService.onLangChange.subscribe(() => {
      this.orderOptions = [
        {
          label: this.translateService.instant('buttons.sent'),
          value: OrderStatus.SENT
        },
        {
          label: this.translateService.instant('buttons.canceled'),
          value: OrderStatus.CANCELLED
        }
      ];
    });
  }

  filterByStatus(event: SelectButtonChangeEvent): void {
    if (event.value === OrderStatus.SENT) {
      this.router.navigate([Route.ORDERS]);
    }
  }

  filterByDate(): void {
    if (this.dateRange.length && this.dateRange[1] !== null) {
      const startDate = moment(this.dateRange[0]).set({ hour: 6, minute: 0 }).format('YYYY-MM-DD HH:mm');
      let endDate = moment(this.dateRange[1]).set({ hour: 6, minute: 0 }).format('YYYY-MM-DD HH:mm');
      if (startDate === endDate) {
        this.dateRange[1] = new Date(moment(startDate).add(1, 'days').format('YYYY-MM-DD HH:mm'));
        endDate = moment(this.dateRange[1]).set({ hour: 6, minute: 0 }).format('YYYY-MM-DD HH:mm');
      }
      this.pTable.filter(startDate, 'startDate', 'dateSameAfter');
      this.pTable.filter(endDate, 'endDate', 'dateSameBefore');
      this.pCalendar.overlayVisible = false;
    }
  }

  clearFilters(): void {
    this.dateRange = [];
    this.pTable.filters = {};
    this.pTable._filter();
  }

  onDateSelect(): void {
    if (
      this.dateRange &&
      this.dateRange[0] &&
      this.dateRange[1] &&
      this.dateRange[0].getTime() === this.dateRange[1].getTime()
    ) {
      this.dateRange = [this.dateRange[0]];
    }
  }

  private getCancelledOrders(): void {
    this.ordersService.getCancelledReservations().subscribe((data) => {
      this.orders = data.map((el) => {
        el.startDate = this.dateService.parseIsoDateToString(el.startDate);
        el.endDate = this.dateService.parseIsoDateToString(el.endDate);
        return el;
      });
    });
  }
}
