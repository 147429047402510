<form [formGroup]="form">
  <div class="flex flex-row gap-2 mb-2">
    <div class="flex flex-column gap-2 w-4">
      <label for="lat">{{ 'adhoc.latitude' | translate }}</label>
      <input type="text" pInputText formControlName="latitude" [maxlength]="coordsMaxLength" />
      <rqa-validator [control]="form.get('latitude')"></rqa-validator>
    </div>
    <div class="flex flex-column gap-2 w-4">
      <label for="lon">{{ 'adhoc.longitude' | translate }}</label>
      <input type="text" pInputText formControlName="longitude" [maxlength]="coordsMaxLength" />
      <rqa-validator [control]="form.get('longitude')"></rqa-validator>
    </div>
  </div>
  <div class="flex flex-row gap-2">
    <div class="flex flex-column gap-2 w-4">
      <label for="radius">{{ 'adhoc.radius' | translate }}</label>
      <p-inputNumber formControlName="radius" mode="decimal" [useGrouping]="false" [maxlength]="6"> </p-inputNumber>
    </div>
    <div class="flex flex-column gap-2 w-4">
      <label for="unit">{{ 'adhoc.unit' | translate }}</label>
      <p-dropdown formControlName="unit" [options]="units" optionLabel="label"></p-dropdown>
    </div>
  </div>
</form>
<div class="flex mt-5 mb-4 gap-2">
  <p-button
    [label]="'buttons.cancel' | translate"
    icon="pi pi-times"
    [text]="true"
    [plain]="true"
    (click)="clear()"
  ></p-button>
  <p-button [label]="'buttons.draw' | translate" icon="pi pi-map" (click)="draw()" [disabled]="!form.valid"></p-button>
</div>
