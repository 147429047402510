<span class="flex align-items-center gap-2 w-full">
  <span class="font-bold white-space-nowrap text-lg"
    >{{ designator }}
    <p-button
      icon="pi pi-clone"
      [rounded]="true"
      [text]="true"
      (click)="copyDesignator($event, designator)"
      [styleClass]="'copy-box-button'"
    ></p-button>
  </span>
  <div *ngIf="serviceAndFrequency.length">
    <span class="rqa-badge" *ngFor="let item of serviceAndFrequency">{{ item }}</span>
  </div>
</span>
<span class="flex align-items-center gap-2 w-full text-sm">{{ name }}</span>
