import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 502 || error.status === 504 || error.status === 403 || error.status === 503) {
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant('notifications.apiError')
          });
        }
        return throwError(error);
      })
    );
  }
}
