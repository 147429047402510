import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../navbar/navbar.component';
import { NavItem } from '../navbar/navbar.interface';
import { RightNavbarComponent } from '../right-navbar/right-navbar.component';

@Component({
  selector: 'rqa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [CommonModule, NavbarComponent, RightNavbarComponent]
})
export class HeaderComponent {
  menuItems: NavItem[] = [
    {
      routerLink: '/',
      label: 'menu.myRqa',
      roles: ['webrqa_user', 'webrqa_draw']
    },
    {
      routerLink: '/orders',
      label: 'menu.orders',
      roles: ['webrqa_user', 'webrqa_draw']
    },
    {
      routerLink: '/templates',
      label: 'menu.templates',
      roles: ['webrqa_user', 'webrqa_draw']
    },
    {
      routerLink: '/adhoc',
      label: 'menu.adhoc',
      roles: ['webrqa_draw']
    },
    {
      routerLink: '/messages',
      label: 'menu.messages',
      roles: ['webrqa_user', 'webrqa_draw']
    },
    {
      routerLink: '/help',
      label: 'menu.help',
      roles: ['webrqa_user', 'webrqa_draw']
    },
    {
      routerLink: '/privacy-policy',
      label: 'menu.privacyPolicy',
      roles: ['webrqa_user', 'webrqa_draw']
    }
  ];
}
