import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[rqaRestrictInput]',
  standalone: true
})
export class RestrictInputDirective {
  private regex: RegExp = new RegExp("[A-Za-z0-9-?:().,'=/+ \\\\ \\n\\r]");

  constructor() {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const inputEvent = event.target as HTMLInputElement;
    const initialValue = inputEvent.value;

    if (!this.regex.test(initialValue)) {
      inputEvent.value = initialValue.slice(0, -1);
    }
  }
}
