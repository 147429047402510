import { Component, Inject, inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { SelectButtonChangeEvent, SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rqa-right-navbar',
  templateUrl: './right-navbar.component.html',
  styleUrls: ['./right-navbar.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, SelectButtonModule, RouterModule, TooltipModule, TranslateModule]
})
export class RightNavbarComponent {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  private keycloakService = inject(KeycloakService);
  private router = inject(Router);
  selectedTheme: string = 'lara-light-indigo';
  themeOptions = [
    { label: 'Light', value: 'lara-light-indigo' },
    { label: 'Dark', value: 'lara-dark-indigo' }
  ];

  switchTheme(theme: SelectButtonChangeEvent) {
    const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
    if (themeLink && theme.value) {
      themeLink.href = theme.value + '.css';
    }
  }
  logout() {
    this.keycloakService.logout().then(() => this.router.navigateByUrl('/'));
  }
}
