import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { InputMaskModule } from 'primeng/inputmask';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ValidatorComponent } from 'src/app/ui/validator/validator.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { PasswordModule } from 'primeng/password';
import { checkPasswords } from 'src/app/shared/form/validators';
import { UserConfigService } from 'src/app/services/user-config.service';
import { PasswordDTO } from 'src/app/model/user-model';

@Component({
  selector: 'rqa-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputMaskModule,
    InputTextareaModule,
    CheckboxModule,
    ValidatorComponent,
    ConfirmDialogModule,
    CardModule,
    PasswordModule,
    TranslateModule
  ]
})
export class PasswordComponent {
  private messageService = inject(MessageService);
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private userConfigService = inject(UserConfigService);

  readonly PASSWORD_PATTERN = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#&%()–{}:;',?/*~$^+=<>])[\\S]{8,15}";
  readonly PASSWORD_PATTERN_MEDIUM = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#&%()–{}:;',?/*~$^+=<>])[\\S]{8,11}";
  readonly PASSWORD_PATTERN_STRONG = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#&%()–{}:;',?/*~$^+=<>])[\\S]{11,15}";

  passwordForm: FormGroup = this.fb.group(
    {
      password: [null, [Validators.required, Validators.pattern(this.PASSWORD_PATTERN)]],
      confirm: [null, Validators.required]
    },
    { validator: checkPasswords }
  );

  save(): void {
    if (this.passwordForm.valid) {
      const payload: PasswordDTO = this.passwordForm.getRawValue();
      this.userConfigService.updateUserPassword(payload).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('notifications.updatePasswordSuccess'),
            life: 3000
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            detail: err.error.message,
            life: 3000
          });
        }
      });
    }
  }
}
