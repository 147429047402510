import { FormGroup, UntypedFormGroup } from '@angular/forms';

export const markFormGroupDirty = (formGroup: UntypedFormGroup) => {
  Object.keys(formGroup.controls).forEach((controlName) => {
    const control = formGroup.get(controlName);

    if (control instanceof FormGroup) {
      markFormGroupDirty(control);
    } else {
      control?.markAsDirty();
    }
  });
};
