import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { CoordinatesFormat } from 'src/app/model/map.model';
import {
  validateDecimalLatitude,
  validateDecimalLongitude,
  validateLatitude,
  validateLongitude
} from 'src/app/shared/form/validators';
import { InputTextModule } from 'primeng/inputtext';
import { ValidatorComponent } from 'src/app/ui/validator/validator.component';
import { AdhocService } from 'src/app/services/adhoc.service';

@Component({
  selector: 'rqa-add-circle',
  templateUrl: './add-circle.component.html',
  styleUrls: ['./add-circle.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    InputNumberModule,
    InputTextModule,
    DropdownModule,
    TranslateModule,
    ValidatorComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class AddCircleComponent implements OnInit, OnChanges {
  @Input() coordinatesFormat: CoordinatesFormat;
  @Input() resetForm: boolean;
  coordinatesType = CoordinatesFormat;
  coordsMaxLength: number = 6;
  form: FormGroup;
  units: { value: string; label: string }[] = [
    { value: 'km', label: 'km' },
    { value: 'm', label: 'm' },
    { value: 'NM', label: 'nm' }
  ];
  private formBuilder = inject(FormBuilder);
  private adhocService = inject(AdhocService);

  ngOnInit(): void {
    this.createForm();
    this.setCoordinatesValidators(this.coordinatesFormat);
  }

  ngOnChanges(changes: SimpleChanges) {
    const coordinatesFormat = changes['coordinatesFormat']?.currentValue;
    if (coordinatesFormat) {
      this.coordsMaxLength = coordinatesFormat === CoordinatesFormat.DMS ? 6 : 7;
    }
    if (this.form) {
      this.setCoordinatesValidators(coordinatesFormat);
    }
    if (changes['resetForm']?.currentValue) {
      this.clear();
    }
  }

  clear(): void {
    this.form.reset();
    this.adhocService.setCircleToDraw(null);
  }

  draw(): void {
    if (this.form.valid) {
      const formData = this.form.getRawValue();
      this.adhocService.setCircleToDraw(formData);
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      latitude: [''],
      longitude: [''],
      radius: [null, Validators.required],
      unit: [{ value: 'km', label: 'km' }, Validators.required]
    });
  }

  private setCoordinatesValidators(formatType: CoordinatesFormat): void {
    if (formatType === CoordinatesFormat.DMS) {
      this.form.get('latitude')?.setValidators([Validators.required, Validators.minLength(6), validateLatitude()]);
      this.form.get('longitude')?.setValidators([Validators.required, Validators.minLength(6), validateLongitude()]);
    }
    if (formatType === CoordinatesFormat.DECIMAL) {
      this.form
        .get('latitude')
        ?.setValidators([Validators.required, Validators.minLength(7), validateDecimalLatitude()]);
      this.form
        .get('longitude')
        ?.setValidators([Validators.required, Validators.minLength(7), validateDecimalLongitude()]);
    }
  }
}
