<section class="flex justify-content-between mb-5 align-items-center px-3 lg:px-0">
  <div>
    <h1 class="text-2xl mx-0 my-0 title">{{ 'templates.title' | translate }}</h1>
    <p class="mx-0 my-0 subtitle">{{ 'templates.subtitle' | translate }}</p>
  </div>
</section>
<section>
  @if (templates) {
    <p-table
      [value]="templates"
      dataKey="uuid"
      [styleClass]="'pansa-table'"
      [scrollable]="true"
      scrollHeight="800px"
      [(selection)]="selectedTemplates"
      [breakpoint]="'960px'"
      (onRowExpand)="onRowToggle($event)"
    >
      <ng-template pTemplate="header">
        <tr class="text-xs">
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="name">
            {{ 'templates.template' | translate }} <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="created">
            {{ 'templates.creationDate' | translate }} <p-sortIcon field="created"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2 w-3"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-template let-expanded="expanded">
        <tr class="text-sm">
          <td>
            <div class="flex align-items-center">
              <p-tableCheckbox [value]="template"></p-tableCheckbox>
            </div>
          </td>
          <td class="p-1">{{ template.name }}</td>
          <td class="p-1">{{ template.created | date: 'yyyy-MM-dd HH:mm' }}</td>
          <td class="p-1">
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="template"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
              [pTooltip]="'templates.preview' | translate"
            ></button>
            <p-button
              icon="pi pi-file-edit"
              (click)="onEditTemplate(template.uuid)"
              [text]="true"
              [plain]="true"
              [rounded]="true"
              [pTooltip]="'templates.edit' | translate"
            ></p-button>
            <p-button
              icon="pi pi-upload"
              (click)="onLoadTemplate(template)"
              [text]="true"
              [plain]="true"
              [rounded]="true"
              [pTooltip]="'templates.use' | translate"
            >
            </p-button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-template>
        <tr class="text-xs">
          <td colspan="7" class="p-1">
            <div class="p-3">
              <p-table [value]="template.templateDetailsDTOS" dataKey="id">
                <ng-template pTemplate="header">
                  <tr class="text-xs">
                    <th class="p-1" pSortableColumn="designator">{{ 'table.header.designator' | translate }}</th>
                    <th class="p-1" pSortableColumn="startTime">{{ 'table.header.startDate' | translate }}</th>
                    <th class="p-1" pSortableColumn="endTime">{{ 'table.header.endDate' | translate }}</th>
                    <th class="p-1" pSortableColumn="lowerAltitude">{{ 'table.header.lowerAltitude' | translate }}</th>
                    <th class="p-1" pSortableColumn="upperAltitude">{{ 'table.header.upperAltitude' | translate }}</th>
                    <th class="p-1" pSortableColumn="remarks">{{ 'table.header.activityType' | translate }}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-templateDetails>
                  <tr class="text-xs">
                    <td class="p-1">{{ templateDetails.designator }}</td>
                    <td class="p-1">{{ templateDetails.startTime }}</td>
                    <td class="p-1">{{ templateDetails.endTime }}</td>
                    <td class="p-1">{{ templateDetails.lowerAltitude }}</td>
                    <td class="p-1">{{ templateDetails.upperAltitude }}</td>
                    <td class="p-1">{{ templateDetails.remarks }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td colspan="8">
            <p-button
              [label]="'buttons.delete' | translate"
              [disabled]="!selectedTemplates.length"
              (click)="onRemoveSelected()"
              [text]="true"
              [plain]="true"
            >
              <rqa-icon iconName="trash" class="p-button-icon-left"></rqa-icon>
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  }
</section>
