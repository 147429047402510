<form [formGroup]="poly">
  <div formArrayName="points" class="coords">
    <div *ngFor="let value of points.controls; let pointValue = index">
      <div class="flex flex-row gap-2 mb-2 align-items-center" [formGroupName]="pointValue">
        <div class="flex flex-column gap-2">
          <label for="lat" *ngIf="pointValue === 0">{{ 'adhoc.latitude' | translate }}</label>
          <input
            type="text"
            pInputText
            [styleClass]="'input-coords'"
            formControlName="lat"
            [maxlength]="coordsMaxLength"
          />
          <rqa-validator [control]="poly.get('points').at(pointValue).get('lat')" class="h-1rem"></rqa-validator>
        </div>
        <div class="flex flex-column gap-2">
          <label for="lon" *ngIf="pointValue === 0">{{ 'adhoc.longitude' | translate }}</label>
          <input
            type="text"
            pInputText
            [styleClass]="'input-coords'"
            formControlName="lon"
            [maxlength]="coordsMaxLength"
          />
          <rqa-validator [control]="poly.get('points').at(pointValue).get('lon')" class="h-1rem"></rqa-validator>
        </div>
        <div class="flex flex-row gap-2 w-6rem h-2rem">
          <p-button
            icon="pi pi-plus"
            (click)="addPoint(pointValue)"
            [text]="true"
            [plain]="true"
            [rounded]="true"
          ></p-button>
          <p-button
            icon="pi pi-times"
            (click)="removePoint(pointValue)"
            [text]="true"
            [plain]="true"
            [rounded]="true"
            [disabled]="points.length < 4"
          ></p-button>
        </div>
      </div>
    </div>
  </div>
  <div class="flex mt-5 mb-4 gap-2">
    <p-button
      [label]="'buttons.cancel' | translate"
      icon="pi pi-times"
      [text]="true"
      [plain]="true"
      (click)="clear()"
    ></p-button>
    <p-button
      [label]="'buttons.draw' | translate"
      icon="pi pi-map"
      (click)="draw()"
      [disabled]="!poly.valid || points.length < 3"
    ></p-button>
  </div>
</form>
