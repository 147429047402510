import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CanceledOrdersComponent } from './canceled-orders/canceled-orders.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'rqa-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    CanceledOrdersComponent,
    RouterModule,
    TranslateModule
  ],
  providers: [RouterModule]
})
export class OrdersComponent {}
