import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { PasswordDTO, UserDTO } from '../model/user-model';
import { LanguageDTO, UserConfig } from '../model/reservation.model';

@Injectable({
  providedIn: 'root'
})
export class UserConfigService {
  constructor(private http: HttpClient) {}
  userConfig = new BehaviorSubject<UserConfig | undefined>(undefined);
  userConfig$: Observable<UserConfig | undefined> = this.userConfig.asObservable();
  isMobileDevice = new BehaviorSubject<boolean | undefined>(undefined);
  isMobileDevice$: Observable<boolean | undefined> = this.isMobileDevice.asObservable();
  CONFIG_PATH = 'api/user-config';

  getTimezone(): string | undefined {
    return this.userConfig.getValue()?.timeZone;
  }

  getIsMobileDevice(): Observable<boolean | undefined> {
    return this.isMobileDevice$;
  }

  setIsMobileDevice(isMobile: boolean): void {
    this.isMobileDevice.next(isMobile);
  }

  getUserConfiguration(): Observable<UserConfig> {
    return this.http.get<UserConfig>(this.CONFIG_PATH).pipe(tap((userConfig) => this.userConfig.next(userConfig)));
  }

  getUserConfigurationInit(): Promise<UserConfig | undefined> {
    return this.http
      .get<UserConfig>(this.CONFIG_PATH)
      .toPromise()
      .then((userConfig) => {
        if (userConfig) {
          this.userConfig.next(userConfig);
        }
        return userConfig;
      })
      .catch((error: HttpErrorResponse) => this.handleError(error));
  }

  getUserConfigurationDetails(): Observable<UserDTO> {
    return this.http.get<UserDTO>(`${this.CONFIG_PATH}/account`);
  }

  updateUserConfig(userConfig: UserDTO): Observable<boolean> {
    return this.http.post<boolean>(`${this.CONFIG_PATH}/account`, userConfig);
  }

  updateUserPassword(passwordDTO: PasswordDTO): Observable<boolean> {
    return this.http.post<boolean>(`${this.CONFIG_PATH}/password`, passwordDTO);
  }

  changeUserLanguage(language: LanguageDTO): Observable<boolean> {
    return this.http.post<boolean>(`${this.CONFIG_PATH}/language`, language);
  }

  private handleError(error: HttpErrorResponse): Promise<never> {
    if ([503, 502, 504, 403].includes(error.status)) {
      window.location.href = 'assets/error.html';
    }
    return Promise.reject(error);
  }
}
