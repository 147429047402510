import { NgModule } from '@angular/core';
import { MapComponent } from './map.component';
import { AirspaceInfoComponent } from './airspace-info/airspace-info.component';
import { AirspaceTableComponent } from './airspace-table/airspace-table.component';
import { SearchMapDialogComponent } from './search-map-dialog/search-map-dialog.component';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { IconComponent } from '../ui/icon/icon.component';
import { ToggleButtonComponent } from '../ui/toggle-button/toggle-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { ValidatorComponent } from '../ui/validator/validator.component';

@NgModule({
  declarations: [MapComponent, AirspaceInfoComponent, AirspaceTableComponent, SearchMapDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TableModule,
    InputTextModule,
    InputNumberModule,
    RadioButtonModule,
    ButtonModule,
    LeafletModule,
    AccordionModule,
    TabViewModule,
    IconComponent,
    ToggleButtonComponent,
    TooltipModule,
    ValidatorComponent
  ],
  exports: [MapComponent]
})
export class MapModule {}
