import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AdHocDTO, ICircleForm } from '../model/adhoc.model';
import { WebrqaResponse } from '../model/reservation.model';

@Injectable({
  providedIn: 'root'
})
export class AdhocService {
  constructor(private http: HttpClient) {}

  CONFIG_PATH = 'api/adhoc';
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private drawedPoly = new BehaviorSubject<any | null>(null);
  private $drawedPoly = this.drawedPoly.asObservable();

  private drawedCircle = new BehaviorSubject<ICircleForm | null>(null);
  private $drawedCircle = this.drawedCircle.asObservable();
  private geoJson = new BehaviorSubject<any | undefined>(undefined);
  private $geoJson = this.geoJson.asObservable();

  private adhocForm = new BehaviorSubject<any | undefined>(undefined);
  private $adhocForm = this.adhocForm.asObservable();

  setPolyToDraw(data: any | null): void {
    this.drawedPoly.next(data);
  }

  getPolyToDraw(): Observable<any | null> {
    return this.$drawedPoly;
  }

  setCircleToDraw(data: ICircleForm | null): void {
    if (data) {
      let radius = data.radius;
      const unit = data?.unit?.value;
      if (unit === 'km') {
        radius = radius * 1000;
      }
      if (unit === 'NM') {
        radius = radius * 1852.0;
      }
      this.drawedCircle.next({ latitude: data.latitude, longitude: data.longitude, radius: radius });
      return;
    }
    this.drawedCircle.next(null);
  }

  getCircleToDraw(): Observable<ICircleForm | null> {
    return this.$drawedCircle;
  }

  setGeoJson(data: any): void {
    this.geoJson.next(data);
  }

  getGeoJson(): Observable<L.GeoJSON> {
    return this.$geoJson;
  }

  setAdhocForm(data: any): void {
    this.adhocForm.next(data);
  }

  getAdhocForm(): Observable<any> {
    return this.$adhocForm;
  }

  createAddHoc(addHocDTO: AdHocDTO): Observable<WebrqaResponse> {
    return this.http.post<WebrqaResponse>(`${this.CONFIG_PATH}`, addHocDTO);
  }

  getMinTime(): Observable<WebrqaResponse> {
    return this.http.get<WebrqaResponse>(`${this.CONFIG_PATH}/minTime`);
  }

  getNewAdHocName(): Observable<WebrqaResponse> {
    return this.http.get<WebrqaResponse>(`${this.CONFIG_PATH}`);
  }
}
