<section class="pb-3">
  <div class="mb-3">
    {{ 'dialogs.cookiePolicy.content' | translate }}
    <a class="nav-link" [routerLink]="'privacy-policy'">{{ 'dialogs.cookiePolicy.more' | translate }}</a>
  </div>

  <p-accordion *ngIf="showMore">
    <p-accordionTab>
      <ng-template pTemplate="header">
        <span class="flex align-items-center justify-content-between gap-2 w-full">
          <div>{{ 'dialogs.cookiePolicy.functional' | translate }}</div>
          <div>
            <p-checkbox [(ngModel)]="functionalChecked" [readonly]="true" [binary]="true" inputId="functional" />
            <label for="functional" class="pl-2">{{ 'dialogs.cookiePolicy.alwaysActive' | translate }}</label>
          </div>
        </span>
      </ng-template>
      <p>
        {{ 'dialogs.cookiePolicy.functionalContent' | translate }}
      </p>
    </p-accordionTab>
    <p-accordionTab>
      <ng-template pTemplate="header">
        <span class="flex align-items-center justify-content-between gap-2 w-full">
          <div>{{ 'dialogs.cookiePolicy.analitycs' | translate }}</div>
          <div>
            <p-checkbox
              [(ngModel)]="analitycsChecked"
              [binary]="true"
              inputId="analitycs"
              (onChange)="onAnalitycsChange($event)"
            />
            <label for="analitycs" class="pl-2">{{
              analitycsChecked
                ? ('dialogs.cookiePolicy.active' | translate)
                : ('dialogs.cookiePolicy.inactive' | translate)
            }}</label>
          </div>
        </span>
      </ng-template>
      <p>
        {{ 'dialogs.cookiePolicy.analitycsContent' | translate }}
      </p>
    </p-accordionTab>
  </p-accordion>
  <div class="flex mb-3 mt-3 align-items-center justify-content-center gap-2">
    <p-button
      [label]="'dialogs.cookiePolicy.acceptAll' | translate"
      icon="pi pi-check"
      (click)="acceptAllCookies()"
    ></p-button>
    <p-button
      [label]="'dialogs.cookiePolicy.acceptSelected' | translate"
      icon="pi pi-check-square"
      severity="info"
      (click)="acceptSelectedCookies()"
    ></p-button>
    <p-button
      [label]="'dialogs.cookiePolicy.decline' | translate"
      severity="secondary"
      (click)="declineCookies()"
    ></p-button>
  </div>
</section>
