import { Component, inject, Input, OnInit } from '@angular/core';
import { NavItem } from './navbar.interface';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { RightNavbarComponent } from '../right-navbar/right-navbar.component';
import { MessagesService } from 'src/app/services/messages.service';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/auth/auth-service';

@Component({
  selector: 'rqa-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, ButtonModule, RightNavbarComponent, BadgeModule, TranslateModule]
})
export class NavbarComponent implements OnInit {
  @Input() items!: NavItem[];
  private messagesService = inject(MessagesService);
  private authService = inject(AuthService);
  private router = inject(Router);
  isMobileMenuOpen = false;
  unreadedCount: number = 0;

  ngOnInit(): void {
    this.getUnreadedCount();
    this.checkUnreadedChange();
  }

  toggleMobileMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  isActive(route: string): boolean {
    const currentUrl = this.router.url;
    return currentUrl === route || currentUrl.startsWith(route + '/');
  }

  canShow(itemRoles: string[]): boolean {
    return this.authService.hasAnyRole(itemRoles);
  }

  public getUnreadedCount(): void {
    this.messagesService.gethUnreadCount().subscribe((unreadedCount) => {
      this.unreadedCount = unreadedCount;
    });
  }

  private checkUnreadedChange(): void {
    this.messagesService.onUnreadCountChange().subscribe((change) => {
      this.unreadedCount += change;
    });
  }
}
