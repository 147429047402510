import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HelpService } from 'src/app/services/help.service';

@Component({
  selector: 'rqa-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, HttpClientModule, CardModule, TranslateModule]
})
export class HelpComponent implements OnInit {
  helpContent: string = '';
  private helpService = inject(HelpService);

  ngOnInit(): void {
    this.helpService.getHelp().subscribe((help) => {
      this.helpContent = help.content;
    });
  }
}
