import { Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ReservationService } from 'src/app/services/reservation.service';
import { ChipModule } from 'primeng/chip';
import { CalendarModule } from 'primeng/calendar';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditMultipleDates, ReservationError, RqaReservationDTO } from 'src/app/model/reservation.model';
import { editDatesValidator } from './dates.validator';
import { ValidatorComponent } from 'src/app/ui/validator/validator.component';
import { TranslateModule } from '@ngx-translate/core';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'rqa-edit-time',
  templateUrl: './rqa-edit-dates.component.html',
  styleUrls: ['./rqa-edit-dates.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    ChipModule,
    ValidatorComponent,
    TranslateModule
  ]
})
export class RqaEditDatesComponent implements OnInit {
  @Input() designators: string[];
  private reservationService = inject(ReservationService);
  private fb = inject(FormBuilder);
  private ref = inject(DynamicDialogRef);
  private config = inject(DynamicDialogConfig);
  private dateService = inject(DateService);
  selectedReservations: RqaReservationDTO[];
  minDate: Date;
  maxDate: Date;
  editDatesForm: FormGroup;
  errorMessages: ReservationError[] = [];

  ngOnInit(): void {
    this.selectedReservations = this.config.data.selectedReservations;
    this.minDate = this.config.data.minDate;
    this.maxDate = this.config.data.maxDate;
    this.createForm();
  }

  onDesignatorRemove(reservation: RqaReservationDTO): void {
    this.selectedReservations = this.selectedReservations.filter((el) => el.uuid !== reservation.uuid);
  }

  save(): void {
    if (this.editDatesForm.valid) {
      const payload: EditMultipleDates = {
        startDate: this.dateService.parseDateToReservationRow(this.editDatesForm.get('startDate')?.value),
        endDate: this.dateService.parseDateToReservationRow(this.editDatesForm.get('endDate')?.value),
        uuidList: this.selectedReservations.map((el) => el.uuid)
      };
      this.reservationService.updateMultiple(payload).subscribe({
        next: (res: RqaReservationDTO[]) => {
          this.ref.close(res);
        },
        error: (err) => {
          console.log(err);
          if (err) {
            if (err.error['reservation-errors']) {
              this.errorMessages = [...err.error['reservation-errors']];
            }
            if (err.error['validation-errors']) {
              this.errorMessages = [...err.error['validation-errors']];
            }
          }
        }
      });
    }
  }

  close(): void {
    this.ref.close();
  }

  getDesignatorByUuid(uuid: string | undefined): string {
    return this.selectedReservations.find((el) => el.uuid === uuid)?.designator || '';
  }

  private createForm(): void {
    this.editDatesForm = this.fb.group(
      {
        startDate: this.fb.control(this.selectedReservations[0].startDate, [Validators.required]),
        endDate: this.fb.control(this.selectedReservations[0].endDate, [Validators.required])
      },
      {
        validator: editDatesValidator
      }
    ) as FormGroup;
  }
}
