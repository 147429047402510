import { KeycloakService } from 'keycloak-angular';
import { UserConfigService } from 'src/app/services/user-config.service';
import { environment } from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService, userConfigService: UserConfigService) {
  return () =>
    keycloak
      .init({
        config: {
          url: environment.keycloak.url,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId
        },
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false,
          enableLogging: true
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets']
      })
      .then(() => userConfigService.getUserConfigurationInit());
}
