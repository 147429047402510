import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DetailedMessageDTO, MessageDTO } from '../model/message-details';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private unreadCountChanged = new Subject<number>();

  constructor(private http: HttpClient) {}

  MESSAGE_PATH = 'api/rqa/message';

  getMessages(): Observable<MessageDTO[]> {
    return this.http.get<MessageDTO[]>(`${this.MESSAGE_PATH}`);
  }

  loadDetails(uuid: string): Observable<DetailedMessageDTO> {
    return this.http.get<DetailedMessageDTO>(`${this.MESSAGE_PATH + '/' + uuid}`);
  }

  delete(uuids: string[]) {
    return this.http.put(`${this.MESSAGE_PATH}/delete`, uuids);
  }

  gethUnreadCount(): Observable<number> {
    return this.http.get<number>(`${this.MESSAGE_PATH}/unread/count`);
  }

  notifyUnreadCountChange(change: number): void {
    this.unreadCountChanged.next(change);
  }

  onUnreadCountChange(): Observable<number> {
    return this.unreadCountChanged.asObservable();
  }
}
