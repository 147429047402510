export interface CancelReservationResponse {
  message: string;
}

export interface OrderStatusOptions {
  label: string;
  value: OrderStatus;
}

export enum OrderStatus {
  SENT = 'SENT',
  CANCELLED = 'CANCELED'
}
