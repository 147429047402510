<div class="flex flex-row gap-2 pt-1 pb-4">
  <div class="flex align-items-center">
    <p-radioButton
      name="dms"
      [(ngModel)]="selectedCoordinatesFormat"
      [value]="coordinatesFormat.DMS"
      inputId="dms"
      (onClick)="onRadioChange($event)"
    ></p-radioButton>
    <label for="dms" class="ml-2">{{ 'adhoc.ddmmssLabel' | translate }}</label>
  </div>

  <div class="flex align-items-center">
    <p-radioButton
      name="decimal"
      [(ngModel)]="selectedCoordinatesFormat"
      [value]="coordinatesFormat.DECIMAL"
      inputId="decimal"
      (onClick)="onRadioChange($event)"
    ></p-radioButton>
    <label for="decimal" class="ml-2">{{ 'adhoc.decimalLabel' | translate }}</label>
  </div>
</div>
<form [formGroup]="form">
  <div class="flex flex-row gap-2">
    <div class="flex flex-column gap-2">
      <label for="lat">{{ 'dialogs.searchMap.latitude' | translate }}</label>
      <input
        type="text"
        pInputText
        formControlName="latitude"
        [maxlength]="coordsMaxLength"
        [pattern]="coordsPattern"
      />
      <rqa-validator [control]="form.get('latitude')"></rqa-validator>
    </div>
    <div class="flex flex-column gap-2">
      <label for="lng">{{ 'dialogs.searchMap.longitude' | translate }}</label>
      <input
        type="text"
        pInputText
        formControlName="longitude"
        [maxlength]="coordsMaxLength"
        [pattern]="coordsPattern"
      />
      <rqa-validator [control]="form.get('longitude')"></rqa-validator>
    </div>
  </div>
</form>
<div class="flex mt-5 mb-4 justify-content-end">
  <p-button
    [label]="'buttons.search' | translate"
    icon="pi pi-search"
    (click)="search()"
    [disabled]="form.invalid"
  ></p-button>
</div>
