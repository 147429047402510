import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private iconsCache: Map<string, SafeHtml> = new Map();

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  loadIcon(iconName: string, width: string = '100%', height: string = '100%'): Observable<SafeHtml | null> {
    if (this.iconsCache.has(iconName)) {
      return of(this.iconsCache.get(iconName)) as Observable<SafeHtml>;
    }

    return this.httpClient.get(`assets/svg/${iconName}.svg`, { responseType: 'text' }).pipe(
      map((svgContent) => {
        const svgWithSize = svgContent.replace('<svg', `<svg style="width: ${width}; height: ${height};"`);
        return this.sanitizer.bypassSecurityTrustHtml(svgWithSize);
      }),
      catchError((error) => {
        console.error(`Error loading icon: ${iconName}`, error);
        return of(null);
      }),
      shareReplay(1)
    );
  }
}
