import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private keycloak: KeycloakService,
    private router: Router,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const requiredRoles = route.data['roles'];

    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    }

    const hasRole = requiredRoles.some((role: string) => this.keycloak.isUserInRole(role));

    if (!hasRole) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('notifications.notAuthorized'),
        life: 3000
      });
      return false;
    }

    return true;
  }
}
