<p-table [value]="_availabilities" [scrollable]="true" scrollHeight="300px">
  <ng-template pTemplate="header">
    <tr class="text-xs">
      <th pSortableColumn="startDate">{{ 'map.box.time' | translate }}</th>
      <th pSortableColumn="lowerAltitude">{{ 'map.box.height' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-availability>
    <tr class="text-xs">
      <td>
        {{ availability.startDate | date: 'dd/MM/yy HH:mm' }} -
        {{ availability.endDate | date: 'dd/MM/yy HH:mm' }}
      </td>
      <td>
        {{ availability.lowerAltitude }} - {{ availability.upperAltitude }}
        <p-button
          icon="pi pi-clone"
          [rounded]="true"
          [text]="true"
          (click)="copyRow(availability)"
          [styleClass]="'copy-box-button'"
        ></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
