<div class="right-navbar">
  <ul class="flex align-items-center">
    <!-- <li>
      <p-selectButton
        [options]="themeOptions"
        [(ngModel)]="selectedTheme"
        optionLabel="label"
        optionValue="value"
        (onChange)="switchTheme($event)"
      ></p-selectButton>
    </li> -->
    <li [routerLink]="'user-config'">
      <i
        class="pi pi-cog"
        style="font-size: 1.25rem"
        tooltipPosition="left"
        [pTooltip]="'tooltips.settings' | translate"
      ></i>
    </li>
    <li (click)="logout()" role="presentation" tabindex="0">
      <i
        class="pi pi-sign-out"
        [pTooltip]="'tooltips.logout' | translate"
        style="font-size: 1.25rem"
        tooltipPosition="left"
      ></i>
    </li>
  </ul>
</div>
