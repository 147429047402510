<section class="flex justify-content-between mb-5 align-items-center px-3 lg:px-0">
  <div>
    <h1 class="text-2xl mx-0 my-0 title">{{ 'userConfig.title' | translate }}</h1>
  </div>
</section>
<p-divider></p-divider>
<section class="flex gap-3 px-3 lg:px-0 flex-column lg:flex-row" *ngIf="userConfig">
  <div class="lg:w-4">
    <h3>{{ 'userConfig.yourData' | translate }}</h3>
    <p class="mx-0 my-0 subtitle">{{ 'userConfig.changeData' | translate }}</p>
  </div>
  <p-card class="lg:w-8">
    <div class="flex flex-column gap-2 mb-4">
      <label for="username">{{ 'userConfig.userName' | translate }}</label>
      <input pInputText id="username" [value]="userConfig.username" [disabled]="true" />
    </div>
    <div class="flex gap-2">
      <div class="flex flex-column gap-2 w-6 mb-4">
        <label for="firstName">{{ 'userConfig.firstName' | translate }}</label>
        <input pInputText id="firstName" [value]="userConfig.firstName" [disabled]="true" />
      </div>
      <div class="flex flex-column gap-2 w-6 mb-4">
        <label for="firstName">{{ 'userConfig.lastName' | translate }}</label>
        <input pInputText id="lastName" [value]="userConfig.lastName" [disabled]="true" />
      </div>
    </div>
    <div class="flex flex-column gap-2 mb-4">
      <label for="email">{{ 'userConfig.email' | translate }}</label>
      <input pInputText id="email" [value]="userConfig.email" [disabled]="true" />
    </div>
    <div class="flex flex-column gap-2 mb-4">
      <label for="phone">{{ 'userConfig.phone' | translate }}</label>
      <input pInputText id="phone" [value]="userConfig.phoneNumbers.join(', ')" [disabled]="true" />
    </div>
    <div class="flex flex-column gap-2 mb-4">
      <label for="responsibleUnit">{{ 'userConfig.responsibleUnit' | translate }}</label>
      <input pInputText id="responsibleUnit" [value]="userConfig.responsibleUnit" [disabled]="true" />
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="organization">{{ 'userConfig.organization' | translate }}</label>
      <input pInputText id="organization" [value]="userConfig.organization" [disabled]="true" />
    </div>
  </p-card>
</section>
<p-divider></p-divider>
<rqa-account [userConfig]="userConfig"></rqa-account>
<p-divider></p-divider>
<rqa-password></rqa-password>
