<div class="flex flex-column gap-2">
  <label for="templateName">{{ 'dialogs.saveTemplate.templateName' | translate }}</label>
  <input
    rqaNoPolishChars
    pInputText
    id="templateName"
    [(ngModel)]="templateName"
    minlength="3"
    maxlength="15"
    oninput="this.value = this.value.toUpperCase()"
  />
  <small id="templateNameError" class="p-error">{{ validationError }}</small>
  <div class="flex mt-5 mb-4 justify-content-between">
    <p-button
      [label]="'buttons.cancel' | translate"
      [text]="true"
      [plain]="true"
      icon="pi pi-minus-circle"
      (click)="close()"
    ></p-button>
    <p-button [label]="'buttons.save' | translate" icon="pi pi-check" (click)="save()"></p-button>
  </div>
</div>
