import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ReservationService } from 'src/app/services/reservation.service';

@Component({
  selector: 'rqa-holidays-info',
  templateUrl: './holidays-info.component.html',
  styleUrl: './holidays-info.component.scss',
  imports: [CommonModule, ButtonModule],
  standalone: true
})
export class HolidaysInfoComponent implements OnInit {
  showInfo: boolean = true;
  infoMsg: string | undefined;
  private reservationService = inject(ReservationService);

  ngOnInit(): void {
    this.showInfo = true;
    this.reservationService.holidaysInfo$.subscribe((result) => {
      this.showInfo = Boolean(result?.value);
      this.infoMsg = result?.value;
    });
  }

  closeInfo(): void {
    this.showInfo = false;
  }
}
