<div class="map-container">
  <div class="toolbar">
    <ul>
      <li>
        <p-button
          [text]="true"
          [plain]="true"
          (click)="zoomIn()"
          tooltipPosition="left"
          [pTooltip]="'tooltips.zoomIn' | translate"
        >
          <rqa-icon iconName="plus-square"></rqa-icon>
        </p-button>
      </li>
      <li>
        <p-button
          [text]="true"
          [plain]="true"
          (click)="zoomOut()"
          tooltipPosition="left"
          [pTooltip]="'tooltips.zoomOut' | translate"
        >
          <rqa-icon iconName="minus-square"></rqa-icon>
        </p-button>
      </li>
      <li class="has-submenu">
        <p-button
          [text]="true"
          [plain]="true"
          (click)="isLayerSubmenuVisible = !isLayerSubmenuVisible"
          tooltipPosition="left"
          [pTooltip]="'tooltips.layers' | translate"
        >
          <rqa-icon iconName="layers"></rqa-icon>
        </p-button>
        <ul class="submenu" *ngIf="isLayerSubmenuVisible" [@slideInFromRight]>
          @for (layer of filterLayerTypes; track layer) {
            <li class="pt-1 pb-1">
              <rqa-toggle-button
                [label]="layer"
                [checked]="disabledAreas.indexOf(layer) === -1"
                (changed)="toggleLayerGroupVisibility($event, layer)"
              ></rqa-toggle-button>
            </li>
          }
        </ul>
      </li>
      <li>
        <p-button
          [text]="true"
          [plain]="true"
          (click)="onSearch()"
          tooltipPosition="left"
          [pTooltip]="'tooltips.search' | translate"
        >
          <rqa-icon iconName="search"></rqa-icon>
        </p-button>
      </li>
    </ul>
  </div>
  <div class="airspace-info">
    <p-accordion (activeIndexChange)="onToggle($event)" [styleClass]="'box-accordion'">
      <p-accordionTab *ngFor="let layer of selectedLayers; trackBy: trackByFn" [id]="layer.id">
        <ng-template pTemplate="header">
          <rqa-airspace-info [airspaceInfo]="layer"></rqa-airspace-info>
        </ng-template>
        <rqa-airspace-table
          [availabilities]="layer.availabilities"
          [designator]="layer.designator"
        ></rqa-airspace-table>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div
    id="map"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="onMapClick($event)"
  ></div>
</div>
