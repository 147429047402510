import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { RadioButtonClickEvent } from 'primeng/radiobutton';
import { Coordinates, CoordinatesFormat } from 'src/app/model/map.model';
import {
  validateDecimalLatitude,
  validateDecimalLongitude,
  validateLatitude,
  validateLongitude
} from 'src/app/shared/form/validators';
import { COORDS_DECIMAL_REGEX, COORDS_DMS_REGEX, ddmmssToDecimal } from 'src/app/shared/utils/coordinates.utils';

@Component({
  selector: 'rqa-search-map-dialog',
  templateUrl: './search-map-dialog.component.html',
  styleUrls: ['./search-map-dialog.component.scss']
})
export class SearchMapDialogComponent implements OnInit {
  private ref = inject(DynamicDialogRef);
  private formBuilder = inject(FormBuilder);
  selectedCoordinatesFormat: CoordinatesFormat = CoordinatesFormat.DMS;
  coordinatesFormat = CoordinatesFormat;
  coordsPattern: RegExp = COORDS_DMS_REGEX;
  coordsMaxLength: number = 6;
  form: FormGroup;

  ngOnInit(): void {
    this.createForm();
  }

  search(): void {
    const payload: Coordinates = this.form.getRawValue();
    payload.latitude =
      this.selectedCoordinatesFormat === CoordinatesFormat.DMS ? ddmmssToDecimal(+payload.latitude) : payload.latitude;
    payload.longitude =
      this.selectedCoordinatesFormat === CoordinatesFormat.DMS
        ? ddmmssToDecimal(+payload.longitude)
        : payload.longitude;
    this.ref.close(payload);
  }

  onRadioChange(event: RadioButtonClickEvent): void {
    this.setCoordinatesValidators(event.value);
    this.coordsPattern = event.value === CoordinatesFormat.DMS ? COORDS_DMS_REGEX : COORDS_DECIMAL_REGEX;
    this.coordsMaxLength = event.value === CoordinatesFormat.DMS ? 6 : 7;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      latitude: [null, [Validators.required, validateLatitude]],
      longitude: [null, [Validators.required, validateLongitude]]
    });
  }

  private setCoordinatesValidators(formatType: CoordinatesFormat): void {
    if (formatType === CoordinatesFormat.DMS) {
      this.form.get('latitude')?.setValidators([Validators.required, validateLatitude()]);
      this.form.get('longitude')?.setValidators([Validators.required, validateLongitude()]);
    }
    if (formatType === CoordinatesFormat.DECIMAL) {
      this.form.get('latitude')?.setValidators([Validators.required, validateDecimalLatitude()]);
      this.form.get('longitude')?.setValidators([Validators.required, validateDecimalLongitude()]);
    }
  }
}
