<section class="flex justify-content-between mb-5 align-items-center px-3 lg:px-0">
  <div>
    <h1 class="text-2xl mx-0 my-0 title">{{ 'cookiePolicy.title' | translate }}</h1>
  </div>
</section>
<section>
  <p-card>
    <div class="cookies-policy">
      <ul>
        <li>
          {{ 'privacyPolicy.part1' | translate }}
        </li>
        <li>
          {{ 'privacyPolicy.part2' | translate }}
        </li>
        <li>
          {{ 'privacyPolicy.part3' | translate }}
        </li>
        <li>
          {{ 'privacyPolicy.part4' | translate }}
        </li>
        <li>
          {{ 'privacyPolicy.part5' | translate }}
        </li>
        <li>
          {{ 'privacyPolicy.part6' | translate }}
        </li>
        <li>
          {{ 'privacyPolicy.part7' | translate }}
        </li>
        <li>
          {{ 'privacyPolicy.part8' | translate }}
        </li>
        <li>
          {{ 'privacyPolicy.cookiesList' | translate }}
          <table>
            <thead>
              <tr>
                <th colspan="3">
                  <h3 class="mt-2 mb-2">{{ 'privacyPolicy.userRelated' | translate }}</h3>
                </th>
              </tr>
              <tr>
                <th>{{ 'privacyPolicy.name' | translate }}</th>
                <th>{{ 'privacyPolicy.description' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td [innerHtml]="'_hjSessionUser_{site_id}'"></td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjSessionUser.part1' | translate }}</li>
                    <li>
                      {{ 'privacyPolicy.hjSessionUser.part2' | translate }}
                    </li>
                    <li>
                      {{ 'privacyPolicy.hjSessionUser.part3' | translate }}
                    </li>
                    <li>{{ 'privacyPolicy.year' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>_hjHasCachedUserAttributes</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjHasCachedUserAttributes' | translate }}</li>
                    <li>{{ 'privacyPolicy.session' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>_hjUserAttributesHash</td>
                <td>
                  <ul>
                    <li>
                      {{ 'privacyPolicy.hjUserAttributesHash.part1' | translate }}
                    </li>
                    <li>{{ 'privacyPolicy.hjUserAttributesHash.duration' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>_hjUserAttributes</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjUserAttributes' | translate }}</li>
                    <li>{{ 'privacyPolicy.noExpiry' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>hjViewportId</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjUserAttributes' | translate }}</li>
                    <li>{{ 'privacyPolicy.noExpiry' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>hjViewportId</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjViewportId' | translate }}</li>
                    <li>{{ 'privacyPolicy.session' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>hjActiveViewportIds</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjActiveViewportIds.part1' | translate }}</li>
                    <li>
                      {{ 'privacyPolicy.hjActiveViewportIds.part2' | translate }}
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <th colspan="3">
                  <h3 class="mt-2 mb-2">{{ 'privacyPolicy.sessionRelated' | translate }}</h3>
                </th>
              </tr>
              <tr>
                <th>{{ 'privacyPolicy.name' | translate }}</th>
                <th>{{ 'privacyPolicy.description' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td [innerHtml]="'_hjSession_{site_id}'"></td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjSession.part1' | translate }}</li>
                    <li>{{ 'privacyPolicy.hjSession.part2' | translate }}</li>
                    <li>{{ 'privacyPolicy.hjSession.part3' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>_hjCookieTest</td>
                <td>
                  <ul>
                    <li>
                      {{ 'privacyPolicy.hjCookieTest.part1' | translate }}
                    </li>
                    <li>{{ 'privacyPolicy.hjCookieTest.part2' | translate }}</li>
                    <li>
                      {{ 'privacyPolicy.hjCookieTest.part31' | translate }}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>_hjLocalStorageTest</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjLocalStorageTest.part1' | translate }}</li>
                    <li>{{ 'privacyPolicy.hjLocalStorageTest.part2' | translate }}</li>
                    <li>
                      {{ 'privacyPolicy.hjLocalStorageTest.part3' | translate }}
                    </li>
                    <li>{{ 'privacyPolicy.100ms' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>_hjSessionStorageTest</td>
                <td>
                  <ul>
                    <li>
                      {{ 'privacyPolicy.hjSessionStorageTest.part1' | translate }}
                    </li>
                    <li>
                      {{ 'privacyPolicy.hjSessionStorageTest.part2' | translate }}
                    </li>
                    <li>{{ 'privacyPolicy.100ms' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>hjTLDTest</td>
                <td>
                  <ul>
                    <li>
                      {{ 'privacyPolicy.hjTLDTest.part1' | translate }}
                    </li>
                    <li>
                      {{ 'privacyPolicy.hjTLDTest.part2' | translate }}
                    </li>
                    <li>{{ 'privacyPolicy.hjTLDTest.part3' | translate }}</li>
                    <li>{{ 'privacyPolicy.session' | translate }}</li>
                  </ul>
                </td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <th colspan="3">
                  <h3 class="mt-2 mb-2">{{ 'privacyPolicy.surveyRelated' | translate }}</h3>
                </th>
              </tr>
              <tr>
                <th>{{ 'privacyPolicy.name' | translate }}</th>
                <th>{{ 'privacyPolicy.description' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_hjClosedSurveyInvites</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjClosedSurveyInvites.part1' | translate }}</li>
                    <li>{{ 'privacyPolicy.hjClosedSurveyInvites.part2' | translate }}</li>
                    <li>{{ 'privacyPolicy.year' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>_hjDonePolls</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjDonePolls.part1' | translate }}</li>
                    <li>{{ 'privacyPolicy.hjDonePolls.part2' | translate }}</li>
                    <li>{{ 'privacyPolicy.year' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>_hjMinimizedPolls</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjMinimizedPolls.part1' | translate }}</li>
                    <li>{{ 'privacyPolicy.hjMinimizedPolls.part2' | translate }}</li>
                    <li>{{ 'privacyPolicy.year' | translate }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>_hjShownFeedbackMessage</td>
                <td>
                  <ul>
                    <li>{{ 'privacyPolicy.hjShownFeedbackMessage.part1' | translate }}</li>
                    <li>{{ 'privacyPolicy.hjShownFeedbackMessage.part2' | translate }}</li>
                    <li>{{ 'privacyPolicy.hjShownFeedbackMessage.part3' | translate }}</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>{{ 'privacyPolicy.summary.part1' | translate }}</li>
        <li>{{ 'privacyPolicy.summary.part2' | translate }}</li>
        <li>{{ 'privacyPolicy.summary.part3' | translate }}</li>
        <li>{{ 'privacyPolicy.summary.part4' | translate }}</li>
      </ul>
    </div>
  </p-card>
</section>
