<section class="flex gap-3 px-3 lg:px-0 flex-column lg:flex-row">
  <div class="lg:w-4">
    <h3>{{ 'userConfig.account' | translate }}</h3>
  </div>
  <p-card class="lg:w-8">
    <form [formGroup]="accountForm">
      <div class="flex flex-column gap-2 mb-4">
        <label for="language">{{ 'userConfig.language' | translate }}</label>
        <p-dropdown [options]="languages" optionLabel="name" formControlName="locale"></p-dropdown>
      </div>
      <div class="flex flex-column gap-2 mb-4">
        <label for="timeZone">{{ 'userConfig.timeZone' | translate }}</label>
        <p-dropdown [options]="timeZones" optionLabel="name" formControlName="time"></p-dropdown>
      </div>
      <div class="flex justify-content-end">
        <p-button [label]="'buttons.save' | translate" icon="pi pi-check" (click)="save()"></p-button>
      </div>
    </form>
  </p-card>
</section>
