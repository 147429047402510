import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[rqaNoPolishChars]',
  standalone: true
})
export class NoPolishCharsDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: KeyboardEvent) {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, '');
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
