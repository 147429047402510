import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const lang = this.translate.currentLang ? this.translate.currentLang : 'EN';
    const httpRequest = req.clone({
      headers: req.headers.set('Accept-Language', lang)
    });
    return next.handle(httpRequest);
  }
}
