import { ReservationDTO } from './reservation.model';

export enum Altitudes {
  SFC,
  GND,
  A001,
  A002,
  A003,
  A004,
  A005,
  A006,
  A007,
  A008,
  A009,
  A010,
  A011,
  A012,
  A013,
  A014,
  A015,
  A016,
  A017,
  A018,
  A019,
  A020,
  A021,
  A022,
  A023,
  A024,
  A025,
  A026,
  A027,
  A028,
  A029,
  A030,
  A031,
  A032,
  A033,
  A034,
  A035,
  A036,
  A037,
  A038,
  A039,
  A040,
  A041,
  A042,
  A043,
  A044,
  A045,
  A046,
  A047,
  A048,
  A049,
  A050,
  A051,
  A052,
  A053,
  A054,
  A055,
  A056,
  A057,
  A058,
  A059,
  A060,
  A061,
  A062,
  A063,
  A064,
  A065,
  A066,
  A067,
  A068,
  A069,
  F070,
  F075,
  F080,
  F085,
  F090,
  F095,
  F100,
  F105,
  F110,
  F115,
  F120,
  F125,
  F130,
  F135,
  F140,
  F145,
  F150,
  F155,
  F160,
  F165,
  F170,
  F175,
  F180,
  F185,
  F190,
  F195,
  F200,
  F205,
  F210,
  F215,
  F220,
  F225,
  F230,
  F235,
  F240,
  F245,
  F250,
  F255,
  F260,
  F265,
  F270,
  F275,
  F280,
  F285,
  F290,
  F295,
  F300,
  F305,
  F310,
  F315,
  F320,
  F325,
  F330,
  F335,
  F340,
  F345,
  F350,
  F355,
  F360,
  F365,
  F370,
  F375,
  F380,
  F385,
  F390,
  F395,
  F400,
  F405,
  F410,
  F415,
  F420,
  F425,
  F430,
  F435,
  F440,
  F445,
  F450,
  F455,
  F460,
  F465,
  F470,
  F475,
  F480,
  F485,
  F490,
  F495,
  F500,
  F505,
  F510,
  F515,
  F520,
  F525,
  F530,
  F535,
  F540,
  F545,
  F550,
  F555,
  F560,
  F565,
  F570,
  F575,
  F580,
  F585,
  F590,
  F595,
  F600,
  F605,
  F610,
  F615,
  F620,
  F625,
  F630,
  F635,
  F640,
  F645,
  F650,
  F655,
  F660,
  UNL
}

const arrayAltA = Array.from({ length: 69 }, (_v, k) => `A` + (k <= 8 ? '00' : '0') + (k + 1));

const arrayAltB = Array.from({ length: 591 }, (v, k) =>
  k % 5 === 0 ? `F` + (k <= 29 ? '0' : '') + (k + 70) : null
).filter((f) => f);

export const altitudeArray: string[] = ['SFC', 'GND', ...(arrayAltA as string[]), ...(arrayAltB as string[]), 'UNL'];

export interface AltitudeChange {
  value: string;
  type: string;
  reservation: ReservationDTO;
}
