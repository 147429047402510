export class DetailedMessageDTO {
  text: string;
  sentTime: Date;
}

export interface MessageDTO {
  uuid: string;
  header: string;
  type: string;
  read: boolean;
  recvTime: Date | string;
  details?: string;
}

export enum MessageType {
  MESSAGE = 'MESSAGE',
  NOTIFICATION = 'NOTIFICATION'
}

export interface MessageTypeOptions {
  label: string;
  value: MessageType;
}
