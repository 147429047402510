import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators
} from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { CoordinatesFormat } from 'src/app/model/map.model';
import { InputTextModule } from 'primeng/inputtext';
import { ValidatorComponent } from 'src/app/ui/validator/validator.component';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { Altitudes } from 'src/app/model/altitudes.model';
import { AdhocService } from 'src/app/services/adhoc.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { altitudesValidator, startDateBeforeEndDateValidator } from 'src/app/shared/form/validators';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'rqa-add-times',
  templateUrl: './add-times.component.html',
  styleUrls: ['./add-times.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    CalendarModule,
    AutoCompleteModule,
    InputTextModule,
    TranslateModule,
    ValidatorComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class AddTimesComponent implements OnInit, OnChanges {
  @Input() coordinatesFormat: CoordinatesFormat;
  @Input() resetForm: boolean;
  form: FormGroup;
  altitudesEnum = Altitudes;
  altitudes: string[];
  filteredAltitudes: string[];
  minDate: Date;
  minTime: Date;
  private formBuilder = inject(FormBuilder);
  private adhocService = inject(AdhocService);
  private reservationService = inject(ReservationService);
  private dateService = inject(DateService);

  ngOnInit(): void {
    this.createForm();
    this.onFormChanges();
    this.altitudes = Object.keys(this.altitudesEnum).filter((k) => isNaN(Number(k)));
    this.adhocService.getMinTime().subscribe((response) => {
      this.minDate = this.dateService.parseIsoDateToDateObj(response.value);
    });
    this.reservationService.getReservableInterval().subscribe((value) => {
      this.minTime = this.dateService.parseIsoDateToDateObj(value.startDate);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['resetForm'].currentValue) {
      this.form.reset();
    }
  }

  searchAltitude(event: AutoCompleteCompleteEvent, field: 'lowerAltitude' | 'upperAltitude'): void {
    const query = event.query.toLowerCase();
    this.filteredAltitudes = this.altitudes.filter((el) => el.toLowerCase().includes(query));
    if (this.filteredAltitudes.length === 1) {
      this.form.get(field)?.setValue(this.filteredAltitudes[0]);
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      datesGroup: this.formBuilder.group(
        {
          startDate: new UntypedFormControl('', [Validators.required]),
          endDate: new UntypedFormControl('', [Validators.required])
        },
        { validator: startDateBeforeEndDateValidator }
      ),
      altitudesGroup: this.formBuilder.group(
        {
          lowerAltitude: [null, Validators.required],
          upperAltitude: [null, Validators.required]
        },
        { validator: altitudesValidator }
      )
    });
  }

  private onFormChanges(): void {
    this.form.statusChanges.subscribe((formStatus) => {
      if (formStatus === 'VALID') {
        const datesGroup = this.form.getRawValue().datesGroup;
        const formPayload = {
          startDate: this.dateService.parseDateToReservationRow(datesGroup.startDate),
          endDate: this.dateService.parseDateToReservationRow(datesGroup.endDate),
          ...this.form.getRawValue().altitudesGroup
        };
        this.adhocService.setAdhocForm(formPayload);
      } else {
        this.adhocService.setAdhocForm(null);
      }
    });
  }
}
