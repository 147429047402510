import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'rqa-policy-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    CardModule,
    TranslateModule,
    CheckboxModule,
    AccordionModule,
    ButtonModule,
    RouterModule
  ],
  providers: [DialogService]
})
export class PolicyDialogComponent {
  analitycsChecked: boolean;
  functionalChecked: boolean = true;
  showMore: boolean = false;
  private ref = inject(DynamicDialogRef);

  goToPolicy(): void {}

  onAnalitycsChange(event: CheckboxChangeEvent): void {
    event.originalEvent?.stopPropagation();
  }

  acceptAllCookies(): void {
    this.ref.close({
      accepted: true,
      analitycsChecked: true
    });
  }

  acceptSelectedCookies(): void {
    if (this.showMore) {
      this.ref.close({
        accepted: true,
        analitycsChecked: this.analitycsChecked
      });
    } else {
      this.showMore = !this.showMore;
    }
  }

  declineCookies(): void {
    this.ref.close({
      accepted: false,
      analitycsChecked: this.analitycsChecked
    });
  }
}
