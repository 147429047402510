import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserConfigService } from 'src/app/services/user-config.service';
import { UserDTO } from 'src/app/model/user-model';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordComponent } from './password/password.component';
import { AccountComponent } from './account/account.component';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'rqa-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    TranslateModule,
    InputTextModule,
    PasswordComponent,
    AccountComponent,
    DividerModule
  ]
})
export class UserConfigComponent implements OnInit {
  private userConfigService = inject(UserConfigService);
  userConfig: UserDTO;

  ngOnInit(): void {
    this.getUserConfig();
  }

  private getUserConfig(): void {
    this.userConfigService.getUserConfigurationDetails().subscribe({
      error: () => {
        this.userConfig = {
          organization: '',
          username: '',
          responsibleUnit: '',
          email: '',
          firstName: '',
          lastName: '',
          time: '',
          locale: '',
          phoneNumbers: []
        };
      },
      next: (value) => {
        if (value) {
          this.userConfig = value;
        }
      }
    });
  }
}
