import { LayersStyle } from '../model/layers.model';

export const layersStyle: LayersStyle = {
  tsa: {
    polygonOptions: {
      color: null,
      weight: 0,
      opacity: 0.0,
      fillOpacity: 0.0
    },
    styles: {
      activated: {
        color: '#786655',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#786655',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      },
      planned: {
        color: '#786655',
        weight: 2,
        opacity: 1,
        fillOpacity: 0,
        dashArray: '5,10'
      },
      multi: {
        color: '#786655',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '5,10'
      }
    }
  },
  r: {
    polygonOptions: {
      color: null,
      weight: 0,
      opacity: 0.0,
      fillOpacity: 0.0
    },
    styles: {
      activated: {
        color: '#FCEC52',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#FCEC52',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      },
      planned: {
        color: '#FCEC52',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      multi: {
        color: '#FCEC52',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      }
    }
  },
  tra: {
    polygonOptions: {
      color: null,
      weight: 0,
      opacity: 0.0,
      fillOpacity: 0.0
    },
    styles: {
      activated: {
        fillColor: '#439966',
        color: '#439966',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#439966',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      },
      planned: {
        color: 'pink',
        fillColor: 'pink',
        weight: 6,
        opacity: 1,
        fillOpacity: 1,
        dashArray: '5,10'
      },
      multi: {
        color: '#439966',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '5,10'
      }
    }
  },
  d: {
    polygonOptions: {
      color: null,
      weight: 0,
      opacity: 0.0,
      fillOpacity: 0.0
    },
    styles: {
      activated: {
        color: '#ea4d36',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#ea4d36',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      },
      planned: {
        color: '#ea4d36',
        weight: 2,
        opacity: 1,
        fillOpacity: 0,
        dashArray: '5,10'
      },
      multi: {
        color: '#ea4d36',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.3,
        dashArray: '5,10'
      }
    }
  },
  atz: {
    polygonOptions: {
      color: null,
      weight: 0,
      opacity: 0.0,
      fillOpacity: 0.0
    },
    styles: {
      activated: {
        color: '#556678',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#556678',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      },
      planned: {
        color: '#556678',
        weight: 2,
        opacity: 1,
        fillOpacity: 0,
        dashArray: '5,10'
      },
      multi: {
        color: '#556678',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '5,10'
      }
    }
  },
  mrt: {
    polygonOptions: {
      color: null,
      weight: 0,
      opacity: 0.0,
      fillOpacity: 0.0
    },
    styles: {
      activated: {
        color: 'rgb(102, 67, 153)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: 'rgb(102, 67, 153)',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      },
      planned: {
        color: 'rgb(102, 67, 153)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0,
        dashArray: '5,10'
      },
      multi: {
        color: 'rgb(102, 67, 153)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '5,10'
      }
    }
  },
  tfr: {
    polygonOptions: {
      color: null,
      weight: 0,
      opacity: 0.0,
      fillOpacity: 0.0
    },
    styles: {
      activated: {
        color: 'rgb(102, 0, 10)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: 'rgb(102, 0, 10)',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      },
      planned: {
        color: 'rgb(102, 67, 153)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0,
        dashArray: '5,10'
      },
      multi: {
        color: 'rgb(102, 67, 153)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '5,10'
      }
    }
  },
  adhoc: {
    polygonOptions: {
      color: null,
      weight: 0,
      opacity: 0.0,
      fillOpacity: 0.0
    },
    styles: {
      activated: {
        color: 'rgb(111, 186, 243)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: 'rgb(111, 186, 243)',
        color: '#499AD8',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      },
      planned: {
        color: 'rgb(111, 186, 243)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0,
        dashArray: '5,10'
      },
      multi: {
        color: 'rgb(111, 186, 243)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '5,10'
      }
    }
  },
  npz: {
    polygonOptions: {
      color: null,
      weight: 0,
      opacity: 0.0,
      fillOpacity: 0.0
    },
    styles: {
      activated: {
        color: 'rgb(247, 171, 242)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: 'rgb(252, 189, 248)',
        color: '#fcbdf8',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      },
      planned: {
        color: 'rgb(247, 171, 242)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0,
        dashArray: '5,10'
      },
      multi: {
        color: 'rgb(247, 171, 242)',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '5,10'
      }
    }
  }
};
