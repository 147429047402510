import { Injectable } from '@angular/core';
import { EPDAirspaceDTO } from '../model/map.model';
import { AirspaceService } from './airspace.service';

@Injectable({
  providedIn: 'root'
})
export class DService {
  table: EPDAirspaceDTO[] = [];

  constructor(private airspace: AirspaceService) {
    this.airspace.getEPDAirspace().subscribe((value) => {
      this.table = value;
    });
  }

  makeValue(designator: string, value: number, type: string): string {
    if (this.table.length) {
      const airspace = this.searchInTable(designator);
      if (airspace) {
        if (value === 0) {
          return 'GND';
        }
        const addition = this.countAddition(value, airspace, type) / 100;
        return this.prepareAltitudeValue(addition);
      }
    }
    return '';
  }

  countAddition(value: number, airspace: EPDAirspaceDTO, type: string): number {
    const count = 3.28 * (Number(value) + Number(airspace.value));
    let addition = Math.ceil(count / 500) * 500;
    const altitude = airspace.altitude;
    if (airspace.addition && type === 'upperAltitude') {
      addition = addition > 6500 ? addition + 1000 : addition + 500;
    }
    return addition > altitude ? altitude : addition;
  }

  isAdditionNeeded(designator: string): boolean {
    const search = this.table.filter((entry) => entry.designator === designator);
    return search[0]?.addition;
  }

  findAltitudeValue(designator: string): number {
    const search = this.table.filter((entry) => entry.designator === designator);
    return search[0]?.altitude;
  }

  addValue(designator: string): number {
    const search = this.table.filter((entry) => entry.designator === designator);
    return search[0]?.value;
  }

  prepare(value: number): string {
    let alt = '';
    if (value > 6500) {
      const result = value / 100;
      alt = result >= 100 ? 'F' + result : 'F0' + result;
    } else {
      const val = value / 100;
      if (val < 10) {
        alt = 'A00' + value / 100;
      } else {
        alt = 'A0' + value / 100;
      }
    }
    return alt;
  }

  searchInTable(designator: string): EPDAirspaceDTO {
    return this.table.filter((entry) => entry.designator === designator)?.[0];
  }

  prepareAltitudeValue(value: number): string {
    let result = '';
    if (value <= 65) {
      if (value < 10) {
        result = value === 0 ? 'GND' : 'A00' + value;
      } else {
        result = 'A0' + value;
      }
    } else if (value >= 70) {
      result = value < 100 ? 'F0' + value : 'F' + value;
    }
    return result;
  }
}
