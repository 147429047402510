import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

export function editDatesValidator(group: UntypedFormGroup) {
  const startDate = group.controls['startDate'].value;
  const endDate = group.controls['endDate'].value;
  if (startDate && endDate) {
    return moment(startDate).isAfter(endDate) ? { dateStartEndError: true } : null;
  }
  return null;
}
