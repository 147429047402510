<nav>
  <ul [ngClass]="{ 'mobile-menu-open': isMobileMenuOpen }">
    <ng-container *ngFor="let item of items">
      <li [class.active]="isActive(item.routerLink)" *ngIf="canShow(item.roles)">
        <a (click)="isMobileMenuOpen = false; getUnreadedCount()" [routerLink]="item.routerLink">
          {{ item.label | translate }}
          @if (item.routerLink === '/messages' && unreadedCount > 0) {
            <p-badge [value]="unreadedCount" severity="danger"></p-badge>
          }
        </a>
      </li>
    </ng-container>
    <li *ngIf="isMobileMenuOpen"><rqa-right-navbar></rqa-right-navbar></li>
  </ul>
  <p-button class="block lg:hidden" icon="pi pi-bars" (click)="toggleMobileMenu()"></p-button>
</nav>
